// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reports-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  background-color: #1c1c1c;
  color: white;
}
@media (max-width: 768px) {
  .reports-header {
    width: 100%;
    height: 55px;
    z-index: 1001;
  }
}

.reports-content {
  position: absolute;
  left: 20%;
  top: 0;
  width: 80%;
  height: 100%;
  background-color: #f5f5f5;
}
@media (max-width: 768px) {
  .reports-content {
    left: 0;
    width: 100%;
    top: 55px;
    height: calc(100% - 55px);
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/reports/styles/index.scss"],"names":[],"mappings":"AAIA;EACI,eAAA;EACA,OAAA;EACA,MAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;AAHJ;AAII;EARJ;IASQ,WAAA;IACA,YAAA;IACA,aAAA;EADN;AACF;;AAIA;EACI,kBAAA;EACA,SAAA;EACA,MAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;AADJ;AAEI;EAPJ;IAQQ,OAAA;IACA,WAAA;IACA,SAAA;IACA,yBAAA;EACN;AACF","sourcesContent":["$mobile: 480px;\n$tablet: 768px;\n$laptop: 1024px;\n$desktop: 1200px;\n.reports-header {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 20%;\n    height: 100%;\n    background-color: #1c1c1c;\n    color: white;\n    @media (max-width: $tablet) {\n        width: 100%;\n        height: 55px;\n        z-index: 1001;\n    }\n}\n\n.reports-content {\n    position: absolute;\n    left: 20%;\n    top: 0;\n    width: 80%;\n    height: 100%;\n    background-color: #f5f5f5;\n    @media (max-width: $tablet) {\n        left: 0;\n        width: 100%;\n        top: 55px;\n        height: calc(100% - 55px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
