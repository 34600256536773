export const REQUEST_SENT_SUCCESSFULLY = {
  en: "Request sent successfully!",
  mk: "Барањето е успешно испратено!",
};

export const REQUEST_SENT_EXPLANATION = {
  en: "Write why you are requesting a vacation and where you are going:",
  mk: "Пиши зашто тражиш одмор и куде ке идеш:",
};

export const BOOKING_BEFORE_START_VACATION = {
  en: "You cannot book before the start of the vacation.",
  mk: "Не може да резервирате пред почетокот на одморот.",
};

export const ERROR_MESSAGE = {
  en: "Error while fetching data.",
  mk: "Грешка при вчитување на податоците.",
};

export const CANNOT_BOOK_IN_MOMENT = {
  en: "You cannot book at the moment. Please try again later",
  mk: "Не може да резервирате во моментов. Ве молиме обидете се подоцна",
};

export const BOOK_IN_PAST = {
  en: "You cannot book dates in the past.",
  mk: "Не може да резервирате датуми во минатото.",
};

export const REQUEST_MUCH_VACATION_DAYS = {
  en: "You cannot book more vacation days than you have.",
  mk: "Не може да резервирате повеќе одмор од колку што имате.",
};
