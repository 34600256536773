// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: var(#f5f5f7) !important;
    color: rgb(29, 29, 31);
    font-style: normal;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card.inView {
    opacity: 1;
    transform: scale(1);
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,0FAA0F;AAC9F;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,wBAAwB;IACxB,0FAA0F;IAC1F,yCAAyC;IACzC,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,+EAA+E;AACnF;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":["html {\n    font-family: \"SF Pro Text\", \"SF Pro Icons\", \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n}\n\nbody {\n    font-size: 17px;\n    line-height: 1.47059;\n    font-weight: 400;\n    letter-spacing: -0.022em;\n    font-family: \"SF Pro Text\", \"SF Pro Icons\", \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    background-color: var(#f5f5f7) !important;\n    color: rgb(29, 29, 31);\n    font-style: normal;\n    margin: 0;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.card.inView {\n    opacity: 1;\n    transform: scale(1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
