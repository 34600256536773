// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/leave-dates.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in-layout {
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  padding: 50px;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.center-content {
  line-height: 0.2rem;
  margin: auto;
  margin-left: 0;
  color: #3a3c3d;
}

.sign-in-col {
  width: 70%;
  position: absolute;
  bottom: 0;
}

.sign-in-title {
  color: #3a3c3d;
  line-height: 35px;
  margin: 0;
}

.sign-in-light {
  color: #a7afbe;
  padding: 0;
  line-height: 20px;
  margin: 0;
}

.input-container {
  width: 250px;
}

.mb-0 {
  margin-bottom: 0;
}

.next-button {
  border-radius: 20px;
  padding: 0 30px;
}`, "",{"version":3,"sources":["webpack://./src/screens/sign-in/styles/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yDAAA;EACA,2BAAA;EACA,4BAAA;EACA,6BAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,mBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;AACJ;;AAEA;EACI,UAAA;EACA,kBAAA;EACA,SAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;EACA,SAAA;AACJ;;AAEA;EACI,cAAA;EACA,UAAA;EACA,iBAAA;EACA,SAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,eAAA;AACJ","sourcesContent":[".sign-in-layout {\n    height: 100vh;\n    background-image: url('../../../../public/leave-dates.jpg');\n    background-position: center;\n    background-repeat: no-repeat;\n    background-color: transparent;\n    background-size: cover;\n    padding: 50px;\n    flex-direction: column;\n    overflow: hidden;\n    width: 100%;\n}\n\n.full-height {\n    height: 100%;\n}\n\n.full-width {\n    width: 100%;\n}\n\n.center-content {\n    line-height: 0.2rem;\n    margin: auto;\n    margin-left: 0;\n    color: #3a3c3d;\n}\n\n.sign-in-col {\n    width: 70%;\n    position: absolute;\n    bottom: 0;\n}\n\n.sign-in-title {\n    color: #3a3c3d;\n    line-height: 35px;\n    margin: 0;\n}\n\n.sign-in-light {\n    color: #a7afbe;\n    padding: 0;\n    line-height: 20px;\n    margin: 0;\n}\n\n.input-container {\n    width: 250px;\n}\n\n.mb-0 {\n    margin-bottom: 0;\n}\n\n.next-button {\n    border-radius: 20px;\n    padding: 0 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
